import React from 'react';
import './index.css';
import SideBar from './layouts/SideBar';
import "./styles/main.scss";
import TopNavbar from './layouts/TopNavbar';
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Home from "./pages/Home.js";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './pages/Login';
import DashBoard from './layouts/DashBoard';
import { useAuth } from './components/AuthProvider';
import Create_MandalLeader from './pages/Create_MandalLeader';
import Create_VillageLeader from './pages/Create_VillageLeader';
import { MapView } from './components/MapView';
import GetReports from './pages/GetReports';
import View_MandalLeaders from './pages/View_MandalLeaders';
import Communications from './pages/Communications';
import View_VillageLeaders from './pages/View_villageLeaders';
import Feeds from './pages/Feeds';
import CreatePost from './components/CreatePost';
import Schemes from './pages/Schemes';
import CreateScheme from './components/CreateScheme';
import Announcements from './pages/Announcements';
import Survey_Polls from './pages/Survey_Polls';
import UploadVotersData from './pages/UploadVotersData';
import Viewvotersdata from './pages/Viewvotersdata';
import VotersListPdf from './components/VotersListPdf.jsx'

function App() {

  const { isLoggedIn } = useAuth();


  console.log(isLoggedIn)
  const userRole = localStorage.getItem('userRole');
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/Dashboard' element={<DashBoard />}>
        <Route path="/Dashboard/mapview" element={<MapView/>} />
          <Route path="/Dashboard/mandal" element={<Create_MandalLeader />} />
          <Route path="/Dashboard/village" element={<Create_VillageLeader />} />
          <Route path='/Dashboard/mandal-leaders' element={<View_MandalLeaders/>}/>
          <Route path='/Dashboard/village-leaders' element={<View_VillageLeaders/>}/>
          <Route path="/Dashboard/reports" element={<GetReports />} />
          <Route path="/Dashboard/communication" element={<Communications />} />
          <Route path="/Dashboard/feeds" element={<Feeds/>}/>
          <Route path="/Dashboard/createpost" element={<CreatePost/>} />
          <Route path="/Dashboard/createpost/:id" element={<CreatePost/>} />
          <Route path="/Dashboard/schemes" element={<Schemes />} />
          <Route path="/Dashboard/createscheme" element={<CreateScheme />} />
          <Route path="/Dashboard/announcements" element={<Announcements/>} />
          <Route path="/Dashboard/survey_polls" element={<Survey_Polls />} />
          <Route path="/Dashboard/votersdata" element={<UploadVotersData />} />
          <Route path="/Dashboard/viewvotersdata" element={<Viewvotersdata/>}/>
          </Route>
          <Route path="/pdf" element={<VotersListPdf />} />
        {isLoggedIn ? (
          <Route path="/Dashboard" element={<Home />} />
        ) : (
          <Route path="/" element={<Navigate to="/Login" />} />
        )}
        <Route path="/Login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}


export default App;