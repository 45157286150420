import React from 'react';
import AdilabadMap from "../react-icons/AdilabadMap.js";
import Map from "../react-icons/Map.js";

export const MapView = () => {
  return (
    <div>
   
     <Map/>
    
    </div>
  );
};
