import * as React from 'react';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';

function randomID(len) {
  let result = '';
  if (result) return result;
  var chars = '12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP',
    maxPos = chars.length,
    i;
  len = len || 5;
  for (i = 0; i < len; i++) {
    result += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return result;
}

export function getUrlParams(
  url = window.location.href
) {
  let urlStr = url.split('?')[1];
  return new URLSearchParams(urlStr);
}

export default function App() {
  const roomID = getUrlParams().get('roomID') || randomID(5);
  let myMeeting = async (element) => {
console.log(myMeeting)
 const appID = 1839844918;
 const serverSecret = "6ca004fefb49a2896ced9f9ce9519e48";
 const kitToken =  ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, roomID,  randomID(5),  randomID(5));
console.log('kit',kitToken)
 // Create instance object from Kit Token.
 const zp = ZegoUIKitPrebuilt.create(kitToken);
 // start the call
//  zp.setConfig({
//   turnOnMicrophoneWhenJoining: true,
//   turnOnCameraWhenJoining: true,
//   showMyCameraToggleButton: true,
//   showMyMicrophoneToggleButton: true,
//   showAudioVideoSettingsButton: true,
//   showScreenSharingButton: true,
//   showTextChat: true,
//   showUserList: true,
//   maxUsers: 50,
//   layout: "Auto",
//   showLayoutButton: true,
// });
console.log('zp',zp)

 zp.joinRoom({
        container: element,
        sharedLinks: [
          {
            name: 'Personal link',
            url:
             window.location.protocol + '//' + 
             window.location.host + window.location.pathname +
              '?roomID=' +
              roomID,
          },
        ],
        scenario: {
         mode: ZegoUIKitPrebuilt.VideoConference,
        },
        turnOnMicrophoneWhenJoining: true,
        turnOnCameraWhenJoining: true,
        showMyCameraToggleButton: true,
        showMyMicrophoneToggleButton: true,
        showAudioVideoSettingsButton: true,
        showScreenSharingButton: true,
        showTextChat: true,
        showUserList: true,
        maxUsers: 50,
        layout: "Auto",
        showLayoutButton: true,

   });
  };
  console.log('meet',myMeeting)
  return (
    <div
      className="myCallContainer"
      ref={myMeeting}
      
  
    >
      
    </div>
    
  );
}