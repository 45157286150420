import React from 'react';
import LiveRoom from '../components/LiveRoom';

const Communications = () => {
  return (
    <div>
      <LiveRoom />
    </div>
  );
};

export default Communications;
