import React, { useEffect, useState } from 'react';
import { Grid } from "@mui/material";
import TabbedCardList from "../components/TabbedCardList";
import api from "../utils/api"
import axios from "axios";
import { Button, IconButton } from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

function GetReports() {
 
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div className="app-page-home">
                <Grid container spacing={2}>
                    <Grid item lg="12">
                        <div className="app-component-reportcard">
                            <div className="app-new-feeds">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={12}>
                                        <div className="app-component-reportcard">
                                            <div className="app-component-reportcard__head">
                                                <h4>Reported</h4>
                                               

                                                {/* <li className="notificaton-card__actions--listitem add-post">
                                                    <IconButton
                                                        id="basic-button"
                                                        aria-controls={open ? 'basic-menu' : undefined}
                                                        aria-haspopup="true"
                                                        aria-expanded={open ? 'true' : undefined}
                                                        onClick={handleClick}
                                                    >
                                                      Reassign Problem
                                                    </IconButton>
                                                    <Menu
                                                        id="basic-menu"
                                                        anchorEl={anchorEl}
                                                        open={open}
                                                        onClose={handleClose}
                                                        MenuListProps={{
                                                            'aria-labelledby': 'basic-button',
                                                        }}
                                                    >
                                         `               <MenuItem >To Mandal Leader</MenuItem>
                                                        <MenuItem >To Village Leader</MenuItem>
                                                        <MenuItem>To Leader Head</MenuItem>
                                                    </Menu>

                                                   
                                                </li> */}
                                            </div>
                                            {/* <div className="app-component-reportcard__count">
                                                <Grid container spacing={6}>
                                                    <Grid item xs={6}>
                                                        <div className="reports-count-card">
                                                           
                                                            <div className="reports-count-card__info">
                                                                <p className="reports-count-card__title">Total Problem Reported</p>
                                                                <div className="reports-count-card__content">
                                                                    <h3 className="reports-count-card__count"></h3>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div className="reports-count-card">
                                                            
                                                            <div className="reports-count-card__info">
                                                                <p className="reports-count-card__title">Total Pending</p>
                                                                <div className="reports-count-card__content">
                                                                    <h3 className="reports-count-card__count"></h3>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div className="reports-count-card">
                                                            
                                                            <div className="reports-count-card__info">
                                                                <p className="reports-count-card__title">Total Solved</p>
                                                                <div className="reports-count-card__content">
                                                                    <h3 className="reports-count-card__count"></h3>
                                                                  
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div className="reports-count-card">
                                                           
                                                            <div className="reports-count-card__info">
                                                                <p className="reports-count-card__title">Failed</p>
                                                                <div className="reports-count-card__content">
                                                                    <h3 className="reports-count-card__count"></h3>
                                                                   
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div> */}
                                            <div className="app-component-announcements__tab">
                                                <TabbedCardList  />
                                            </div>
                                            
                                        </div>
                                    </Grid>
                                   
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default GetReports;