import React, { useEffect, useState } from 'react';
import { Grid } from "@mui/material";
import { noauthinstance } from '../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const View_MandalLeaders = () => {
    const [agents, setAgents] = useState([]);
    const [error, setError] = useState(null);

    const fetchAgents = async () => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            console.log(accessToken)
            if (!accessToken) {
                throw new Error('Authentication credentials were not provided.');
            }

            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const response = await noauthinstance.get('leader/superagents',{headers});
            setAgents(response.data.users);
        } catch (err) {
            console.error('Error fetching agents:', err);
            setError(err);
        }
    };

    useEffect(() => {
        fetchAgents();
    }, []);

    return (
        <>
            <div className="app-page-home">
                <Grid>
                    <Grid item lg="12">
                        <div className="app-component-reportcard">
                            <div className="app-new-feeds">
                                <Grid>
                                    <div className="app-component-reportcard">
                                        <div className="app-component-reportcard__head">
                                            <h4>List of mandals Created by leader</h4>
                                        </div>
                                        {error && (
                                            <div className="error-message">
                                                <p>Error: {error.message}</p>
                                            </div>
                                        )}
                                        <table className="custom-table">
                                            <thead>
                                                <tr>
                                                    
                                                    <th>First Name</th>
                                                    <th>Last Name</th>
                                                    <th>phone</th>
                                            
                                                    <th>Roles</th>
                                                    <th>mandal</th>
                                                   
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {agents.map((agent) => (
                                                    <tr >
                                                    
                                                        <td>{agent.first_name || '-'}</td>
                                                        <td>{agent.last_name || '-'}</td>
                                             
                                                        <td>{agent.phone || '-'}</td>
                                                        <td>{agent.roles || '-'}</td>
                                                        <td>{agent.mandal ? agent.mandal.name : '-'}</td>

                                                      
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <ToastContainer />
            </div>
        </>
    );
};

export default View_MandalLeaders;
