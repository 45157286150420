import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Button, IconButton } from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import api from "../utils/api"
import { toast } from 'react-toastify';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>
                    {children}
                </div>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};



function TabbedCardList({ fetcreports }) {
    const [value, setValue] = React.useState();
    const userRole = localStorage.getItem('userRole');
    useEffect(() => {

        if (userRole === 'admin') {
          setValue(0); 
        } else if (userRole === 'superagent') {
          setValue(0); 
        }
      }, [userRole]);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [reports, setReports] = React.useState([]);
    const [reportsError, setReportsError] = React.useState(null);

    const [villageReports, setVillageReports] = React.useState([]);
    const [mandalReports, setMandalReports] = React.useState([]);
    const [failedReports, setFailedReports] = React.useState([]);



    console.log(reports);

    const fetchReports = async () => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            console.log(accessToken)
            if (!accessToken) {
                throw new Error('Authentication credentials were not provided.');
            }

            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await api.get("leader/reports");

            console.log({ t: response.data.reports })
            setReports(response.data.reports);


        } catch (err) {
            console.error("Error fetching data:", err);
            setReportsError(err);
        }
    };
    const fetchVillageReports = async () => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            console.log(accessToken)
            if (!accessToken) {
                throw new Error('Authentication credentials were not provided.');
            }

            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await api.get("reports/agent/");

            console.log({ t: response.data.reports })
            setVillageReports(response.data);


        } catch (err) {
            console.error("Error fetching data:", err);
            setReportsError(err);
        }
    };
    const fetchMandalReports = async () => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            console.log(accessToken)
            if (!accessToken) {
                throw new Error('Authentication credentials were not provided.');
            }

            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await api.get("reports/superagent/");

            console.log({ t: response.data.reports })
            setMandalReports(response.data);


        } catch (err) {
            console.error("Error fetching data:", err);
            setReportsError(err);
        }
    };
    console.log(reports);
    React.useEffect(() => {
        fetchReports();
    }, []);



    return (
        <>
            <div className="app-component-tabbed-card-list">
                <div className="app-component-tabbed-card-list__head">
                {userRole === 'admin' && (
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Problems Reported" />
              
            </Tabs>
             )}
            {userRole === 'superagent' && (
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Problems Reported to Mandal Level" />
                </Tabs>
              )}
         
                </div>
                <div className="app-component-tabbed-card-list__content">
                    <CustomTabPanel value={value} index={0}>
                        <ul className="notificaton-card__list report-image-scroll">
                            {reports && reports.map((item, inddex) => {
                                console.log('image', item.report_image)
                                return (
                                    <div className='notificaton-card-div'>
                                        <li className="notificaton-card__listitem">
                                            <div className="notificaton-card__info">
                                                <h3>{item.report}</h3>
                                                <p>{item.address}</p>
                                                <p>{new Date(item.updatedAt).toDateString()}</p>
                                            </div>

                                        </li>
                                        {/* <h4>Reported Image</h4>
                                        <img src={`http://65.2.174.18:70${item.report_image}`} alt="" />
 */}

                                    </div>
                                )
                            })}

                        </ul>

                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <ul className="notificaton-card__list report-image-scroll">
                            {mandalReports && mandalReports.map((item, inddex) => {
                                console.log('image', item.report_image)
                                return (
                                    <div className='notificaton-card-div'>
                                        <li className="notificaton-card__listitem">
                                            <div className="notificaton-card__info">
                                                <h4>{item.report}</h4>
                                                <p>{item.address}</p>
                                                <span>{new Date(item.updatedAt).toDateString()}</span>
                                            </div>

                                        </li>
                                        {/* <h4>Reported Image</h4>
                                        <img src={`http://65.2.174.18:70${item.report_image}`} alt="" /> */}


                                    </div>
                                )
                            })}

                        </ul>

                    </CustomTabPanel>




                </div>
            </div>
        </>
    )
}
export default TabbedCardList;