import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import api, { noauthinstance } from '../utils/api';
import { CircularProgress } from "@mui/material"
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Create_Villageleader = () => {
    const [first_name, setFirstname] = useState('');
    const [last_name, setLastname] = useState('');

    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const[village,setVillage]=useState('')
    const [error, setError] = useState("")
    const [constituencyData, setConstituencyData] = useState([]);
    const [loading, setLoading] = useState(false);
    const fetchConstituency = async () => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            console.log(accessToken)
            if (!accessToken) {
                throw new Error('Authentication credentials were not provided.');
            }

            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await noauthinstance.get('villages/by_superagent',{headers});
            setConstituencyData(response.data);
        } catch (err) {
            console.error('Error fetching data:', err);
            setError(err);
        }
    };

    
  

    const postAgent = async () => {
        const formData = new FormData();
        formData.append('first_name', first_name);

        formData.append('last_name', last_name);
        formData.append('phone', phone);
        formData.append('password', password);
        formData.append('village', village);
        setLoading(true);   
        try {
            const accessToken = localStorage.getItem('accessToken');
            console.log(accessToken)
            if (!accessToken) {
                throw new Error('Authentication credentials were not provided.');
            }

            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await api.post('user/agent-register/', formData,{headers});
            if (response) {
                toast.success('Agent successfully created!');
            }
            setLastname('');
            setPhone('');
            setVillage('');
            setPassword('');
            setFirstname('');
        } catch (err) {
            console.error('Error fetching data:', err);
            setError(err);
        }
        finally {
            setLoading(false); // Set loading to false when the API request is complete
        }
    };

    useEffect(() => {
        fetchConstituency();
    }, []);

    return (
        <>
            <div className="app-page-home">
                <Grid>
                    <Grid item lg="12">
                        <div className="app-component-reportcard">
                            <div className="app-new-feeds">
                                <Grid>
                                    <div className="app-component-reportcard">
                                        <div className="app-component-reportcard__head">
                                            <h4>Create Village Leader</h4>
                                        </div>
                                        <div className="app-component-reportcard__count">
                                            <div className="select-tags">
                                                <div className="form-group">
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <label>First Name</label>
                                                            <input
                                                                value={first_name}
                                                                onChange={(e) => setFirstname(e.target.value)}
                                                                type="text"
                                                                className="input-form"
                                                                placeholder="Enter FirstName"
                                                            ></input>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <label>Last Name</label>
                                                            <input
                                                                value={last_name}
                                                                onChange={(e) => setLastname(e.target.value)}
                                                                type="text"
                                                                className="input-form"
                                                                placeholder="Enter LastName"
                                                            ></input>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </div>
                                       
                                            <div className="form-group">
                                                <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                        <label>Mobile Number</label>
                                                        <input
                                                            value={phone}
                                                            onChange={(e) => setPhone(e.target.value)}
                                                            type="text"
                                                            className="input-form"
                                                            placeholder="Enter Mobile Number"
                                                        ></input>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <label>Password</label>
                                                        <input
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            type="text"
                                                            className="input-form"
                                                            placeholder="Enter password"
                                                        ></input>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div className="form-group">
                                                <Grid container spacing={2}>
                                                
                                                    <Grid item xs={6}>
                                                        <label>Select Village</label>
                                                        <select
                                                            className="input-form"
                                                            value={village}
                                                            onChange={(e) => setVillage(e.target.value)}
                                                        >
                                                            <option value="">village</option>
                                                            {constituencyData.map((option, index) => (
                                                                <option key={index} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </Grid>
                                                   
                                                </Grid>
                                            </div>
                                        </div>
                                        <div className="create-post-button">
                                        <button onClick={postAgent} disabled={loading}>
    {loading ? <CircularProgress size={24} /> : "Create Leader"}
</button>
                                        
                                        </div>
                                    </div>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <ToastContainer position="top-right" />
        </>
    );
};

export default Create_Villageleader;
