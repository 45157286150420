import React from 'react';
import { Document, Page, Text, View, StyleSheet,Font } from '@react-pdf/renderer';
import ObjectivityBold from '../objectivity/Objectivity-Bold.ttf'
import ObjectivityRegular from '../objectivity/Objectivity-Regular.ttf'
import NotoSansRegular from '../Noto_Sans_Telugu/NotoSansTelugu-Regular.ttf'


const PDFDocument = ({ voters }) => {
  console.log('in pdf', voters)
  Font.register({
    family: 'Objectivity',
    fonts: [
      { src: ObjectivityRegular, fontWeight: 'normal' },
      { src: ObjectivityBold, fontWeight: 'bold' },
    ],
  });
  Font.register({
    family: 'NotoSansTelugu',
    src: NotoSansRegular,
  });
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      padding: 10,
    },
    card: {
      width: '29.5%',
      backgroundColor: '#d1d0d0',
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
      border: '1px solid black',
      margin: 10,
      padding: 10,
      fontSize: 10,
    },
    label: {
      fontFamily: 'Objectivity',
      fontSize: 10, 
    },
    boldLabel: {
      fontFamily: 'Objectivity',
      fontSize: 10, 
      fontWeight: 'bold',
    },
      teluguText: {
      fontFamily: 'NotoSansTelugu', 
      fontSize: 10,
    },
    cardNumberContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginBottom: '10px',

    },
    cardNumber: {
      fontSize: 10,
      border: '1px solid black',
      width: 50,
      textAlign: 'right',
    },
  });
  try {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          {voters.map((voter, index) => {
            console.log('Mapping voter:', voter);
            return (
              <View key={voter.id} style={styles.card}>
                <View style={styles.cardNumberContainer}>
                  <Text style={styles.cardNumber}>{index + 1}</Text>
                  <Text style={styles.label}>{voter.voterId_no}</Text>
                </View>

                <Text style={styles.label}><Text style={styles.boldLabel}>Name:</Text> {voter.name} {voter.surname}</Text>
                <Text style={styles.label}><Text style={styles.boldLabel}>Age:</Text> {voter.age} <Text style={styles.boldLabel}>Gender:</Text> {voter.gender}</Text>
                <Text style={styles.label}><Text style={styles.boldLabel}>House number:</Text> {voter.address}, {voter.resident}</Text>
                <Text style={styles.label}>
                  <Text style={styles.boldLabel}>Booth no:</Text> {voter.booth_no} <Text style={styles.boldLabel}>Caste:</Text>
                  <Text style={styles.teluguText}>{voter.caste}</Text> 
                </Text>
                <Text style={styles.label}><Text style={styles.boldLabel}>Mobile:</Text> {voter.mobile}</Text>
                <Text style={styles.label}><Text style={styles.boldLabel}>Party:</Text> {voter.party} <Text style={styles.boldLabel}>Joint-family:</Text> {voter.joint_family ? 'Yes' : 'No'}</Text>
                <Text style={styles.label}><Text style={styles.boldLabel}>Benificers:</Text><Text style={styles.teluguText}> {voter.benificers}</Text> </Text>
                <Text style={styles.label}><Text style={styles.boldLabel}>Occupation:</Text> {voter.occupation}</Text>


              </View>
            );
          })}

        </Page>
      </Document>
    );
  } catch (error) {
    console.error('Error rendering PDF:', error);
    return null; // Return null or an error message component if rendering fails
  }
};

export default PDFDocument;
