import { Button, Grid,CircularProgress } from "@mui/material";
import person from '../Icons/person.svg';
import dots from '../Icons/dots.svg';
import post1 from '../images/mp-lands/post-1.svg';
import post2 from '../images/mp-lands/post-2.svg';
import like from '../Icons/like.svg';
import comment from '../Icons/comment.svg';
import repost from '../Icons/re-post.svg';
import share from '../Icons/share.svg';
import { useEffect, useState } from "react";
import api from "../utils/api"
import { Link } from "react-router-dom";
import EditScheme from "../components/EditScheme";
import {HiOutlineUserCircle} from'react-icons/hi2';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Schemes() {

    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [editingPostId, setEditingPostId] = useState(null);
    const [loading, setLoading] = useState(true);
    console.log(data)

    const fetcScheme = async () => {
        try {
            const response = await api.get("schemas/")
            setData(response.data);
            setLoading(false);
        } catch (err) {
            console.error('Error fetching data:', err);
            setError(err);
            setLoading(false);
        }
    };
    const handleClickEdit = (postId) => {
        setEditingPostId(postId);
    };

    const handleCancelEdit = () => {
        setEditingPostId(null);
    };
    const handleDelete = async (itemId) => {

        try {

            await api.delete(`schemas/${itemId}`);

            fetcScheme()
            toast.success("Scheme deleted successfully!");

        } catch (error) {

            console.error('Error deleting item:', error);
            toast.error("An error occurred while deleting the scheme.");

        }

    }

    useEffect(() => {
        fetcScheme()
    }, [])

    return (
        <>
            <div className="app-page-home">
                <Grid container spacing={2}>
                    <Grid item lg="12">
                        <div className="app-component-reportcard">
                            <div className="app-new-feeds">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={12}>
                                        <div className="new-feeds-content">
                                            <div className="feeds-top-content">
                                                <h4>Schemes</h4>
                                            </div>
                                            <div className="add-post">
                                                <Link to="/Dashboard/CreateScheme"> <Button >Add Scheme</Button></Link>
                                            </div>
                                        </div>
                                        <div className="schemes-scroll">
                                        {loading && <CircularProgress />} {/* Show circular progress while loading */}
                                        {error && <div>Error fetching data. Please try again later.</div>}
                                        {!loading && !error && data && data.map((item, index) => (
                                            <div className="app-new-feeds-content-box" key={item.id}>
                                                {editingPostId === item.id ? (
                                                    <EditScheme schemedata={item} />
                                                ) : (
                                                    <div className="app-new-feeds-box-content">
                                                        <div key={index} className="person-info">
                                                            <div className="person-inf0-deatils">
                                                                <ul className="person-info-display">
                                                                    <li>
                                                                        <HiOutlineUserCircle />
                                                                    </li>
                                                                    <li>{item.user.first_name}  {item.user.last_name}
                                                                        <span>
                                                                            <h5>MLA</h5>
                                                                            <h6>TRS</h6>
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <div className="person-info-content">
                                                                    <h4>{item.title}</h4>
                                                                    <p>{item.state} {new Date(item.updatedAt).toDateString()}</p>
                                                                </div>
                                                            </div>
                                                            <ul className="menu-dots">
                                                                <li>
                                                                    <div className="dropdown">
                                                                        <button className="dropbtn">
                                                                            <img src={dots} alt="dots" />
                                                                        </button>
                                                                        <div className="dropdown-content">
                                                                            <a><Button onClick={() => handleClickEdit(item.id)}>Edit</Button></a>
                                                                            <a><Button onClick={() => handleDelete(item.id)}>Delete</Button></a>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="post-image">
                                                            <img src={item.image} alt="scheme-image" />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
        <ToastContainer />
    </>
);
}

export default Schemes;