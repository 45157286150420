import { Button, Grid,CircularProgress } from "@mui/material";
import { useRef, useState } from "react";

import api from "../utils/api"
import { useNavigate } from "react-router-dom";
import SideFeeds from "./SideFeeds";
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'



function CreatePost() {

    const navigate = useNavigate("")

    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState("")
    // const [tags, setTags] = useState("")
    const [description, setDescription] = useState("")
    const [image, setImage] = useState(null)
    // const [selectedFile, setSelectedFile] = useState(null);
    let fileInputRef = useRef(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        // setSelectionFile(file)
        setImage(file)
    };


  
    const handlePost = async (e) => {
        e.preventDefault();

        if (!title.trim() || !description.trim() || !image) {
            toast.error("Please fill in all the required fields.");
            return;
        }

        const formData = new FormData();
        formData.append("image", image);
        formData.append("title", title);
        formData.append("description", description);

        try {
            setLoading(true);
            const { data, status } = await api.post("posts/", formData);
            if (status === 201) {
                toast.success("Post successfully created!");
                navigate("/Dashboard/feeds");
                // Reset input fields
                setImage(null);
                setTitle("");
                setDescription("");
                // Reset file input
                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }
            } else {
                toast.error("Failed to create post. Please try again.");
            }
        } catch (error) {
            console.error("An error occurred while creating the post:", error);
            toast.error("Failed to create post. Please try again.");
        } finally {
            setLoading(false);
        }
    };







    return (
        <>
            <div className="app-page-home">
                <Grid container spacing={2}>
                    <Grid item lg="12">
                        <div className="app-component-reportcard">
                            <div className="app-new-feeds">
                                <Grid container spacing={2}>
                                    <Grid item xs={6} lg={6}>
                                        <div className="app-component-reportcard">
                                            <div className="app-component-reportcard__head">
                                                <h4>Create Post</h4>
                                            </div>
                                            <div className="app-component-reportcard__count">
                                                <div className="form-group">
                                                    <input
                                                        type="file"
                                                        onChange={handleFileChange}
                                                        ref={(input) => (fileInputRef = input)}
                                                        className="input-form"
                                                        id=""
                                                    />
                                                </div>
                                                <div className="select-tags">
                                                    {/* <div className="form-group">
                                                        <label>Tag</label>
                                                        <input value={tags} onChange={(e) => setTags(e.target.value)} type="text" className="input-form" id="" placeholder="@kishore" ></input>
                                                    </div> */}
                                                    <div className="form-group">
                                                        <label>Title</label>
                                                        <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" className="input-form" id="" placeholder="Title" ></input>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label>Description</label>
                                                    <textarea value={description} onChange={(e) => setDescription(e.target.value)} className="input-form-2" rows="5" placeholder="Please share what you’re thinking"></textarea>
                                                </div>
                                            </div>
                                            <div className="create-post-button">
                                            <Button onClick={handlePost}>
                                                    {loading ? <CircularProgress size={24} /> : "Create Post"}
                                                </Button>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} lg={6}>
                                        <div className="app-page-home__feeds">
                                            <SideFeeds/>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <ToastContainer position="top-right" />
        </>
    )
}

export default CreatePost;