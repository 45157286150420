import React from 'react'
import { Button, Grid } from "@mui/material";
import api, { noauthinstance } from "../utils/api"
import { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material"
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Create_MandalLeader = () => {


    const [first_name, setFirstname] = useState("");

    const [last_name, setLastname] = useState("");

    

    const [password, setPassword] = useState("");

    const [phone, setPhone] = useState("")

    const [mandal, setMandal] = useState('');

    const [loading, setLoading] = useState(false);

    
    const [constituencyData, setConstituencyData] = useState([]);
    

    const fetchConstituency = async () => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            console.log(accessToken)
            if (!accessToken) {
                throw new Error('Authentication credentials were not provided.');
            }

            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            
            const response = await noauthinstance.get("mandals/by_leader",{headers})
            setConstituencyData(response.data);
        } catch (err) {
            console.error('Error fetching data:', err);
          
        }
    };

    useEffect(() => {
        fetchConstituency()
    }, [])

    const postAdmin = async () => {

        const formData = new FormData();

        formData.append("first_name", first_name);
   
        formData.append("last_name", last_name);
        formData.append("phone", phone);
        formData.append("password", password);
        formData.append("mandal", mandal);
       
      
        setLoading(true);   
        try {
            const accessToken = localStorage.getItem('accessToken');
            console.log(accessToken)
            if (!accessToken) {
                throw new Error('Authentication credentials were not provided.');
            }

            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await api.post("user/superagent-register/", formData,{headers})
            if (response) {
                toast.success("Admin successfully created!")
            }
            setMandal("");
            setLastname("");
            setPhone("");
            setPassword("");
            setFirstname("")
        } catch (err) {
            console.error('Error fetching data:', err);
            toast.error("Please fill all fields");
           
        }
        finally {
            setLoading(false); // Set loading to false when the API request is complete
        }
    };






    return (
        <>
            <div className="app-page-home">
                <Grid >
                    <Grid item lg="12">
                        <div className="app-component-reportcard">
                            <div className="app-new-feeds">
                                <Grid >
                                    <div className="app-component-reportcard">
                                        <div className="app-component-reportcard__head">
                                            <h4>Create Mandal Leader</h4>
                                        </div>
                                        <div className="app-component-reportcard__count">
                                            <div className="select-tags">
                                                <div className="form-group">
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <label>First Name</label>
                                                            <input value={first_name} onChange={(e) => setFirstname(e.target.value)} type="text" className="input-form" placeholder="Enter FirstName"></input>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <label>Last Name</label>
                                                            <input value={last_name} onChange={(e) => setLastname(e.target.value)} type="text" className="input-form" placeholder="Enter LastName"></input>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </div>
                                           
                                            <div className="form-group">
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <label>Mobile Number</label>
                                                        <input value={phone} onChange={(e) => setPhone(e.target.value)} type="text" className="input-form" placeholder="Enter Mobile Number"></input>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <label>password</label>
                                                        <input value={password} onChange={(e) => setPassword(e.target.value)} type="text" className="input-form" placeholder="Enter password"></input>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div className="form-group">
                                                <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                        <label>Select Mandal</label>
                                                        <select className="input-form"
                                                            value={mandal}
                                                            onChange={(e) => setMandal(e.target.value)}
                                                        >
                                                            <option value="" disabled selected>mandal</option>
                                                            {constituencyData.map((option, index) => (
                                                                <option key={index} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </Grid>
                                                    
                                                </Grid>
                                            </div>
                                        </div>
                                        <div className="create-post-button">
                                        <button onClick={postAdmin} disabled={loading}>
    {loading ? <CircularProgress size={24} /> : "Create Leader"}
</button>

                                        </div>
                                    </div>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div >
            <ToastContainer position="top-right" />
        </>
    )
}

export default Create_MandalLeader;