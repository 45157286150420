import React from 'react';
import { Button } from "@mui/material";
import DashboardIcon from "../Icons/DashboardIcon";
import SurveysIcon from "../Icons/SurveysIcon";
import SchemesIcon from "../Icons/SchemesIcon";
import SettingsIcon from "../Icons/SettingsIcon";
import { Link, useNavigate } from "react-router-dom";
import FeedsIcon from "../Icons/FeedsIcon";
import ReportedIcon from "../Icons/ReportedIcon";
import { PiUserCircleThin } from 'react-icons/pi'
import NotificationsIcon from "../Icons/NotificationsIcon";

function SideBar() {
    const userRole = localStorage.getItem('userRole');
    console.log('userolee', userRole)
    const navigate = useNavigate();
    const first_name = localStorage.getItem('first_name');
    return (
        <>
            <div className="app-sidebar">
                <div className="app-sidebar-logo">
                    <img src="https://www.svgrepo.com//show/59753/team-leader.svg" />
                    <h3>YOUR <span>LEADER</span></h3>
                </div>
                <div className='loggedin-user'>
                    <div className="pi-user-thin"><PiUserCircleThin /> <span>{first_name}</span> </div>
                </div>
                <div className="app-sidebar-menu">
                    <ul className="app-sidebar-menu-list">
                        <li className="app-sidebar-menu-list-item">
                            <Link to="/Dashboard/mapview">
                                <Button className="active">
                                    <span className="app-sidebar-menu-title">Dashboard</span>
                                </Button>
                            </Link>
                        </li>

                        {userRole === 'admin' && (
                            <li className="app-sidebar-menu-list-item">
                                <Link to="/Dashboard/mandal">
                                    <Button>
                                        <span className="app-sidebar-menu-title">Create Mandal Leader</span>
                                    </Button>
                                </Link>
                            </li>
                        )}
                        {userRole === 'superagent' && (
                            <li className="app-sidebar-menu-list-item">
                                <Link to="/Dashboard/village">
                                    <Button>
                                        <span className="app-sidebar-menu-title">Create Village Leader</span>
                                    </Button>
                                </Link>
                            </li>
                        )}
                        {userRole != 'superagent' && (
                            <li className="app-sidebar-menu-list-item">

                                <Link to="/Dashboard/mandal-leaders"><Button><span className="app-sidebar-menu-title">View Mandal Leaders</span></Button></Link>

                            </li>
                        )}
                        {userRole === 'superagent' && (
                            <li className="app-sidebar-menu-list-item">

                                <Link to="/Dashboard/village-leaders"><Button><span className="app-sidebar-menu-title">View Village Leaders</span></Button></Link>

                            </li>
                        )}
                        <li className="app-sidebar-menu-list-item">

                            <Link to="/Dashboard/reports"><Button><span className="app-sidebar-menu-title">View Tickets</span></Button></Link>

                        </li>
                        <li className="app-sidebar-menu-list-item">

                            <Link to="/Dashboard/communication"><Button><span className="app-sidebar-menu-title">Communication</span></Button></Link>

                        </li>
                        <li className="app-sidebar-menu-list-item">

                            <Link to="/Dashboard/feeds"> <Button><span className="app-sidebar-menu-title">Feeds</span></Button></Link>

                        </li>
                        <li className="app-sidebar-menu-list-item">

                            <Link to="/Dashboard/schemes"> <Button><span className="app-sidebar-menu-title">Schemes</span></Button></Link>

                        </li>
                        <li className="app-sidebar-menu-list-item">

                            <Link to="/Dashboard/announcements"> <Button><span className="app-sidebar-menu-title">Announcements</span></Button></Link>

                        </li>
                        <li className="app-sidebar-menu-list-item">

                            <Link to="/Dashboard/survey_polls"><Button><span className="app-sidebar-menu-title">Surveys / Polls</span></Button></Link>

                        </li>
                        <li className="app-sidebar-menu-list-item">
                            <Link to="/Dashboard/votersdata">
                                <Button>
                                    <span className="app-sidebar-menu-title">Upload VotersData</span>
                                </Button>
                            </Link>
                        </li>
                        <li className="app-sidebar-menu-list-item">
                            <Link to="/Dashboard/viewvotersdata">
                                <Button>
                                    <span className="app-sidebar-menu-title">View VotersData</span>
                                </Button>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}
export default SideBar;
